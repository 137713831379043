import React, { useState } from "react";
import Header from "../Header";
import { Link } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [resetSuccess, setResetSuccess] = useState(false);
  const { resetPassword } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await resetPassword(email);
      setResetSuccess(true);
      setError("");
    } catch (error) {
      console.log(error.message);
      if (error.code) {
        switch (error.code) {
          case "auth/user-not-found":
            setError("User not found.");
            break;
          default:
            setError("An error occurred while resetting the password.");
        }
      } else {
        setError("An error occurred while resetting the password.");
      }
    }
  };

  return (
    <div>
      <Header></Header>
      <div id="Auth">
        <div className="Center-Container">
          <div className="Center">
            <h1>Reset Password</h1>
            <form onSubmit={handleSubmit}>
              <div className="Text-Field">
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email"
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>
              <button type="submit">Reset Password</button>
              <div className="Signup-Link">
                <Link to="/login">
                  <p>Return to login</p>
                </Link>
              </div>
              <div className="Error-Message">{error && <p>{`${error}`}</p>}</div>
              <div className="Success-Dialog">
                {resetSuccess && <h2>Password Reset Request Sent</h2>}
                {resetSuccess && <p>A password reset message was sent to your email.  Please click the link in the message to reset your password.</p>}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;