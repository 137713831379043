import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import "../index.css";
import { useAuth } from "../contexts/AuthContext";

function Header() {
  const [open, setOpen] = useState(false);
  const menu = useRef();
  const { currentUser, logout } = useAuth();

  function openMenu() {
    setOpen(!open);
  }

  return (
    <div id="header">
      <div id="header-left">
        <button id="menu-btn">
          <img src="images/menu.svg" alt="menu" onClick={openMenu} />
        </button>
        <h1>Qwytt</h1>
      </div>
      <div
        id="header-right-sm"
        ref={menu}
        style={{ height: open ? "14em" : "0em", width: open ? "70vw" : "0em" }}
      >
        <Link to="/">
          <button className="header-button">Application</button>
        </Link>
        <Link to="/device">
          <button className="header-button">Device</button>
        </Link>
        {/* <Link to="/demo">
          <button className="header-button">Demo</button>
        </Link> */}
        {/* <Link to="/news">
          <button className="header-button">News</button>
        </Link> */}
        {!currentUser && (
          <Link to="/login">
            <button className="header-button">Login</button>
          </Link>
        )}
        {currentUser && (
          <Link to="/account">
            <button className="header-button" onClick={logout}>
              Account
            </button>
          </Link>
        )}
        {currentUser && (
          <Link to="/">
            <button className="header-button" onClick={logout}>
              Sign Out
            </button>
          </Link>
        )}
      </div>
      <div id="header-right" ref={menu}>
        <Link to="/">
          <button className="header-button">Application</button>
        </Link>
        <Link to="/device">
          <button className="header-button">Device</button>
        </Link>
        {!currentUser && (
          <Link to="/login">
            <button className="header-button">Login</button>
          </Link>
        )}
        {currentUser && (
          <Link to="/account">
            <button className="header-button" onClick={logout}>
              Account
            </button>
          </Link>
        )}
        {currentUser && (
          <Link to="/">
            <button className="header-button" onClick={logout}>
              Sign Out
            </button>
          </Link>
        )}
      </div>
    </div>
  );
}

export default Header;
