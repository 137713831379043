import React, { useState } from "react";
import Header from "../Header";
import { useNavigate } from "react-router-dom";
import { getFirestore, collection, doc, getDocs, query, where, updateDoc, deleteDoc, arrayRemove, Timestamp } from 'firebase/firestore';
import { getAuth, signInWithEmailAndPassword, deleteUser } from 'firebase/auth';
import { app } from "../../firebase-config";


const Account = () => {
  let navigate = useNavigate();
  const db = getFirestore(app);
  const auth = getAuth();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmation, setConfirmation] = useState("");
  const [acctDeleteError, setAcctDeleteError] = useState("");
  const [dataDeleteSuccess, setDataDeleteSuccess] = useState(false);
  const [dataDeleteError, setDataDeleteError] = useState("");

  const handleDeleteAccount = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);

      const userDoc = doc(db, "users", auth.currentUser.uid);

      const subCollections = ['hours', 'days', 'months'];

      for (const subCollection of subCollections) {
        const subCollectionRef = collection(userDoc, subCollection);
        const subCollectionDocs = await getDocs(subCollectionRef);

        subCollectionDocs.forEach(async (doc) => {
          await deleteDoc(doc.ref);
        });
      }

      const userGroupsQuery = query(collection(db, "groups"), where("members", "array-contains", auth.currentUser.uid));
      const userGroups = await getDocs(userGroupsQuery);
      userGroups.forEach(async (group) => {
        await updateDoc(doc(db, "groups", group.id), {
          members: arrayRemove(auth.currentUser.uid),
        });
      });

      deleteDoc(userDoc);

      await signInWithEmailAndPassword(auth, email, password);
      await deleteUser(auth.currentUser);

      console.log(auth.currentUser);

      setDataDeleteError("");
      setDataDeleteSuccess(true);
      navigate("/");
    } catch (acctDeleteError) {
      console.log(acctDeleteError.message);
      if (acctDeleteError.response && acctDeleteError.response.data.error) {
        switch(acctDeleteError.response.data.error){
          case 'auth/user-not-found':
            setAcctDeleteError("That email is not in use.");
            break;
          case 'auth/invalid-email':
            setAcctDeleteError("The email address is not valid.");
            break;
          default:
            setAcctDeleteError("An error occurred while deleting your account.");
        }
      } else {
        setAcctDeleteError(acctDeleteError);
      }
    }
  };

  const handleDeleteData = async (e) => {
    e.preventDefault();
    try {
      if (confirmation !== "on") {
        throw new Error("Please confirm that you understand this action is irreversible.");
      }

      const userDoc = doc(db, "users", auth.currentUser.uid);

      const subCollections = ['hours', 'days', 'months'];

      for (const subCollection of subCollections) {
        const subCollectionRef = collection(userDoc, subCollection);
        const subCollectionDocs = await getDocs(subCollectionRef);

        subCollectionDocs.forEach(async (doc) => {
          await deleteDoc(doc.ref);
        });
      }

      const userDocData = (await userDoc.get()).data();

      const fieldsToSave = [
        "show_ads",
        "username",
        "email",
        "first_name",
        "last_name",
        "date_of_birth",
        "user_type"
      ];

      for (const field in userDocData) {
        if (field in fieldsToSave == false) {
          await updateDoc(userDoc, {
            [field]: null,
          });
        }
      }

      const userGroupsQuery = query(collection(db, "groups"), where("members", "array-contains", auth.currentUser.uid));
      const userGroups = await getDocs(userGroupsQuery);
      userGroups.forEach(async (group) => {
        await updateDoc(doc(db, "groups", group.id), {
          members: arrayRemove(auth.currentUser.uid),
        });
      });

      await updateDoc(doc(db, "users", auth.currentUser.uid), {
        timestamp_last_deletion: Timestamp.now(),
        timestamp_last_update: Timestamp.now(),
      });

      setDataDeleteError("");
    } catch (dataDeleteError) {
      console.log(dataDeleteError.message);
      if (dataDeleteError.response && dataDeleteError.response.data.error) {
        setDataDeleteError(dataDeleteError.response.data.error);
      } else {
        setDataDeleteError(dataDeleteError);
      }
    }
  };

  return (
    <div>
      <Header></Header>
      <div id="Account">
        <h1>Manage Account</h1>
        <div className="Center-Container">
          <div className="Center">
            <h2>Delete Account</h2>
            <p>Please re-enter credentials to confirm</p>

            <form onSubmit={handleDeleteAccount}>
              <div className="Text-Field">
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email"
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>
              <div className="Text-Field">
                <input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
              </div>
              <div className="Error-Message">{acctDeleteError && <p>{`${acctDeleteError}`}</p>}</div>
              <button type="submit">Delete</button>
            </form>
          </div>
          <div className="Center">
            <h2>Delete User Data</h2>
            <p>Includes vaping activity, groups, and all other non-account data.</p>

            <form onSubmit={handleDeleteData}>
              <div className="Text-Field">
                <label htmlFor="confirm">I understand that this action is irreversible.</label>
                <input
                  type="checkbox"
                  name="confirm"
                  id="confirm"
                  onChange={(e) => {
                    setConfirmation(e.target.value);
                  }}
                />
              </div>
              <div className="Error-Message">{dataDeleteError && <p>{`${dataDeleteError}`}</p>}</div>
              <button type="submit">Delete</button>
              <div className="Success-Dialog">
                {dataDeleteSuccess && <p>Data successfully deleted.</p>}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Account;
